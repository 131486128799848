export type ABExperiment = { id: string; variants: string[] };

// define experiments "<startDate> - <description>"
// make sure we use unique experiment ids
export const EXPERIMENTS: { [key: string]: ABExperiment } = {
  '211031 - Homepage Length': {
    id: '211031 - Homepage Length',
    variants: ['Long - 27 animations', 'Short - 9 animations'],
  },
};
