// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-cookie-policy-index-tsx": () => import("./../../../src/pages/cookie-policy/index.tsx" /* webpackChunkName: "component---src-pages-cookie-policy-index-tsx" */),
  "component---src-pages-for-resellers-tsx": () => import("./../../../src/pages/for-resellers.tsx" /* webpackChunkName: "component---src-pages-for-resellers-tsx" */),
  "component---src-pages-host-tsx": () => import("./../../../src/pages/host.tsx" /* webpackChunkName: "component---src-pages-host-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-lp-product-reviews-tsx": () => import("./../../../src/pages/lp/product-reviews.tsx" /* webpackChunkName: "component---src-pages-lp-product-reviews-tsx" */),
  "component---src-pages-lp-reseller-reviews-tsx": () => import("./../../../src/pages/lp/reseller-reviews.tsx" /* webpackChunkName: "component---src-pages-lp-reseller-reviews-tsx" */),
  "component---src-pages-lp-transparent-pricing-tsx": () => import("./../../../src/pages/lp/transparent-pricing.tsx" /* webpackChunkName: "component---src-pages-lp-transparent-pricing-tsx" */),
  "component---src-pages-privacy-policy-index-tsx": () => import("./../../../src/pages/privacy-policy/index.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-index-tsx" */),
  "component---src-pages-products-dev-2-tsx": () => import("./../../../src/pages/products-dev2.tsx" /* webpackChunkName: "component---src-pages-products-dev-2-tsx" */),
  "component---src-pages-products-dev-tsx": () => import("./../../../src/pages/products-dev.tsx" /* webpackChunkName: "component---src-pages-products-dev-tsx" */),
  "component---src-pages-return-and-refund-policy-index-tsx": () => import("./../../../src/pages/return-and-refund-policy/index.tsx" /* webpackChunkName: "component---src-pages-return-and-refund-policy-index-tsx" */),
  "component---src-pages-ro-cauta-furnizori-index-tsx": () => import("./../../../src/pages/ro/cauta-furnizori/index.tsx" /* webpackChunkName: "component---src-pages-ro-cauta-furnizori-index-tsx" */),
  "component---src-pages-ro-cauta-produse-index-tsx": () => import("./../../../src/pages/ro/cauta-produse/index.tsx" /* webpackChunkName: "component---src-pages-ro-cauta-produse-index-tsx" */),
  "component---src-pages-ro-cauta-profesionisti-index-tsx": () => import("./../../../src/pages/ro/cauta-profesionisti/index.tsx" /* webpackChunkName: "component---src-pages-ro-cauta-profesionisti-index-tsx" */),
  "component---src-pages-ro-index-tsx": () => import("./../../../src/pages/ro/index.tsx" /* webpackChunkName: "component---src-pages-ro-index-tsx" */),
  "component---src-pages-ro-lp-calculator-deschidere-restaurant-tsx": () => import("./../../../src/pages/ro/lp/calculator-deschidere-restaurant.tsx" /* webpackChunkName: "component---src-pages-ro-lp-calculator-deschidere-restaurant-tsx" */),
  "component---src-pages-ro-lp-ghid-deschidere-restaurant-tsx": () => import("./../../../src/pages/ro/lp/ghid-deschidere-restaurant.tsx" /* webpackChunkName: "component---src-pages-ro-lp-ghid-deschidere-restaurant-tsx" */),
  "component---src-pages-ro-oferta-mea-checkout-index-tsx": () => import("./../../../src/pages/ro/oferta-mea/checkout/index.tsx" /* webpackChunkName: "component---src-pages-ro-oferta-mea-checkout-index-tsx" */),
  "component---src-pages-ro-oferta-mea-index-tsx": () => import("./../../../src/pages/ro/oferta-mea/index.tsx" /* webpackChunkName: "component---src-pages-ro-oferta-mea-index-tsx" */),
  "component---src-pages-team-index-tsx": () => import("./../../../src/pages/team/index.tsx" /* webpackChunkName: "component---src-pages-team-index-tsx" */),
  "component---src-pages-terms-and-conditions-index-tsx": () => import("./../../../src/pages/terms-and-conditions/index.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-index-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/BlogPost.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-product-tsx": () => import("./../../../src/templates/Product.tsx" /* webpackChunkName: "component---src-templates-product-tsx" */),
  "component---src-templates-professional-tsx": () => import("./../../../src/templates/Professional.tsx" /* webpackChunkName: "component---src-templates-professional-tsx" */),
  "component---src-templates-supplier-tsx": () => import("./../../../src/templates/Supplier.tsx" /* webpackChunkName: "component---src-templates-supplier-tsx" */),
  "component---src-templates-team-member-tsx": () => import("./../../../src/templates/TeamMember.tsx" /* webpackChunkName: "component---src-templates-team-member-tsx" */)
}

