import { Product } from '@/../graphql-types';
import { isClient } from '@/lib/gatsby';
import * as localforage from 'localforage';
import React, { FC, useCallback, useEffect, useState } from 'react';

// change this if we change the domain
const PRODUCTS_KEY = 'PRODUCTS_KEY_2';
let shoppingBasketStore;

if (isClient()) {
  localforage.ready().then(() => {
    shoppingBasketStore = localforage.createInstance({
      name: 'shoppingBasket',
    });
  });
}

export type ShoppingBasketEntry = {
  product: Product;
  quantity: number;
  resellers: string[];
  queryId?: string;
};

export function entryIsValid(entry: ShoppingBasketEntry) {
  return (
    !!entry.quantity &&
    !!entry.product?.averageMaxPrice?.value &&
    !!entry.product?.averageMaxPrice?.currency
  );
}

async function getItems(): Promise<ShoppingBasketEntry[]> {
  return (await shoppingBasketStore?.getItem(PRODUCTS_KEY)) ?? [];
}

async function addToBasket(
  newEntry: ShoppingBasketEntry
): Promise<ShoppingBasketEntry[]> {
  if (!entryIsValid(newEntry)) throw new Error('Invalid ShoppingBasketEntry');

  const basketEntries = await getItems();
  const entry = basketEntries.find(
    (it) => it?.product?.code === newEntry.product.code
  );
  if (entry) {
    entry.quantity += newEntry.quantity;
    entry.resellers = newEntry.resellers;
    entry.queryId = newEntry.queryId;
  } else {
    basketEntries.push(newEntry);
  }

  await shoppingBasketStore?.setItem(PRODUCTS_KEY, basketEntries);

  return basketEntries;
}

async function removeEntry(
  newEntry: ShoppingBasketEntry
): Promise<ShoppingBasketEntry[]> {
  const basketEntries = (await getItems())
    .filter(entryIsValid)
    .filter((it) => it.product.code !== newEntry.product.code);

  await shoppingBasketStore?.setItem(PRODUCTS_KEY, basketEntries);

  return basketEntries;
}

async function clearBasket(): Promise<void> {
  await shoppingBasketStore?.setItem(PRODUCTS_KEY, []);
}

async function updateEntries(
  newEntries: ShoppingBasketEntry[]
): Promise<ShoppingBasketEntry[]> {
  await shoppingBasketStore?.setItem(
    PRODUCTS_KEY,
    newEntries.filter(entryIsValid)
  );

  return newEntries;
}

export const ShoppingBasketContext = React.createContext<{
  items: ShoppingBasketEntry[];
  addToBasket(entry: ShoppingBasketEntry): Promise<void>;
  removeEntry(entry: ShoppingBasketEntry): Promise<void>;
  updateEntries(entries: ShoppingBasketEntry[]): Promise<void>;
  clearBasket(): Promise<void>;
}>({
  items: [],
  async addToBasket() {
    throw new Error('Context not initialized');
  },
  async removeEntry() {
    throw new Error('Context not initialized');
  },
  async updateEntries() {
    throw new Error('Context not initialized');
  },
  async clearBasket() {
    throw new Error('Context not initialized');
  },
});

export const ShoppingBasketContextWrapper: FC = ({ children }) => {
  const [entries, setEntries] = useState([]);

  useEffect(() => {
    let cancelled = false;

    (async () => {
      const basketEntries = await getItems();
      if (cancelled) return;
      setEntries(basketEntries);
    })();

    return () => {
      cancelled = true;
    };
  }, [setEntries]);

  const addToBasketCallback = useCallback(
    async (newEntry: ShoppingBasketEntry) => {
      setEntries(await addToBasket(newEntry));
    },
    [setEntries]
  );

  const removeEntryCallback = useCallback(
    async (newEntry: ShoppingBasketEntry) => {
      setEntries(await removeEntry(newEntry));
    },
    [setEntries]
  );

  const updateEntriesCallback = useCallback(
    async (newEntries: ShoppingBasketEntry[]) => {
      setEntries(await updateEntries(newEntries));
    },
    [setEntries]
  );

  const clearBasketCallback = useCallback(async () => {
    await clearBasket();
    setEntries([]);
  }, [setEntries]);

  return (
    <ShoppingBasketContext.Provider
      value={{
        items: entries,
        addToBasket: addToBasketCallback,
        removeEntry: removeEntryCallback,
        updateEntries: updateEntriesCallback,
        clearBasket: clearBasketCallback,
      }}
    >
      {children}
    </ShoppingBasketContext.Provider>
  );
};
