import { ABExperiment, EXPERIMENTS } from '@/abTestDefinitions';
import { ShoppingBasketContextWrapper } from '@/lib/shoppingBasket';
import '@fontsource/poppins/300.css';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/700.css';
import { emitter, experimentDebugger } from '@marvelapp/react-ab-test';
import { GatsbyBrowser } from 'gatsby';
import React from 'react';

import '../../styles/generated-html.css';
import '../../styles/global.css';

let shouldCleanExperimentCookie = true;

function enableExperiment(experiment: ABExperiment) {
  // enable experiments
  emitter.defineVariants(experiment.id, experiment.variants);
  emitter.calculateActiveVariant(experiment.id);

  shouldCleanExperimentCookie = false;
  document.cookie = `experiment_variant=[${
    experiment.id
  }][${emitter.getActiveVariant(experiment.id)}]`;
}

enableExperiment(EXPERIMENTS['211031 - Homepage Length']);

if (shouldCleanExperimentCookie) {
  document.cookie = `experiment_variant=;expires=Thu, 01 Jan 1970 00:00:01 GMT`;
}

experimentDebugger.enable();

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({
  element,
}) => {
  return <ShoppingBasketContextWrapper>{element}</ShoppingBasketContextWrapper>;
};
